import clsx from "clsx";
import { useMemo } from "react";
import { EmailIcon } from "../Icons/components/ShareIcons/EmailIcon.component";
import { LinkedInIcon } from "../Icons/components/ShareIcons/LinkedInIcon.component";
import { MsTeamsIcon } from "../Icons/components/ShareIcons/MsTeamsIcon.component";
import { Translation } from "../Translation/Translation.component";
import { ShareLink } from "./ShareLink.component";
type ShareButtonModel = {
  readonly encodedUrl: string;
  readonly title: string;
};
type ShareButtonsProps = {
  readonly className?: string;
  readonly model: ShareButtonModel;
};
export function ShareButtons({
  className,
  model: {
    encodedUrl,
    title
  }
}: ShareButtonsProps) {
  const encodedTitle = useMemo(() => encodeURIComponent(title), [title]);
  return <div className={clsx(className, "flex flex-wrap items-center")} data-sentry-component="ShareButtons" data-sentry-source-file="ShareButtons.component.tsx">
      <h3 className="py-1 pr-2 text-sm font-theme-bold uppercase tracking-tight text-dim">
        <Translation da="Del artikel" de="Jetzt teilen" en="Share article" no="Del artikkel" sv="Dela artikel" data-sentry-element="Translation" data-sentry-source-file="ShareButtons.component.tsx" />
      </h3>

      <div className="flex items-center space-x-2">
        <ShareLink label="LinkedIn" title={title} url={`https://www.linkedin.com/shareArticle?url=${encodedUrl}?utm_source=linkedin&utm_content=article_share_linkedin`} data-sentry-element="ShareLink" data-sentry-source-file="ShareButtons.component.tsx">
          <LinkedInIcon className="h-5 w-5" data-sentry-element="LinkedInIcon" data-sentry-source-file="ShareButtons.component.tsx" />
        </ShareLink>

        <ShareLink label="Microsoft Teams" title={title} url={`https://teams.microsoft.com/share?href=${encodedUrl}&msgText=${encodedTitle}&referrer=${encodedUrl}?utm_source=ms_teams&utm_content=article_share_ms_teams`} data-sentry-element="ShareLink" data-sentry-source-file="ShareButtons.component.tsx">
          <MsTeamsIcon className="h-5 w-5" data-sentry-element="MsTeamsIcon" data-sentry-source-file="ShareButtons.component.tsx" />
        </ShareLink>

        <ShareLink label="Email" title={title} url={`mailto:?subject=${encodedTitle}&body=${encodedUrl}?utm_source=mail&utm_content=article_share_mail`} data-sentry-element="ShareLink" data-sentry-source-file="ShareButtons.component.tsx">
          <EmailIcon className="h-5 w-5" data-sentry-element="EmailIcon" data-sentry-source-file="ShareButtons.component.tsx" />
        </ShareLink>
      </div>
    </div>;
}