"use client";

import type { ImageRatio } from "@/components/images/image.types.ts";
import { useImageConfiguration } from "@/contexts/image-configuration/image-configuration.context";
import type { ArticleContentModel } from "@/services/api/content/models/article-content-model.types";
import { imageLoader } from "@/utilities/image-loader";
import clsx from "clsx";
import NextImage from "next/image";
type SupportingImageProps = {
  readonly image: ArticleContentModel.SupportingImage;
  readonly caption: null | string;
  readonly ratio?: ImageRatio;
  readonly sourceSetSize: string;
};
export function SupportingImage({
  caption,
  image,
  ratio = "4:3",
  sourceSetSize
}: SupportingImageProps) {
  const {
    serverUrl
  } = useImageConfiguration();
  const imageClassName: Record<ImageRatio, string> = {
    "3:2": "aspect-3/2",
    "4:3": "aspect-4/3",
    "16:9": "aspect-16/9"
  };
  const imageRatio: Record<ImageRatio, string> = {
    "3:2": image.aspectRatio3By2,
    "4:3": image.aspectRatio4By3,
    "16:9": image.aspectRatio16By9
  };
  return <figure data-sentry-component="SupportingImage" data-sentry-source-file="SupportingImage.tsx">
      <div className={clsx(["relative w-full", imageClassName[ratio]])}>
        <NextImage alt={caption ?? ""} className="bg-whisper object-cover" fill={true} loader={imageLoader(serverUrl)} priority={true} sizes={sourceSetSize} src={imageRatio[ratio]} data-sentry-element="NextImage" data-sentry-source-file="SupportingImage.tsx" />
      </div>
      {caption !== null ? <figcaption className="pt-1 text-sm text-dim">{caption}</figcaption> : null}
    </figure>;
}