"use client";

import type { ShopLinks } from "@/app/_api/shop/_models/shop-links.model";
import { useExperiment } from "@/hooks/use-experiment.hook";
import UnauthenticatedTrialFocusPaywallSplitA from "./UnauthenticatedTrialFocusPaywallSplitA.component";
import UnauthenticatedTrialFocusPaywallSplitB from "./UnauthenticatedTrialFocusPaywallSplitB.component";
import type { PaywallModel } from "./models/paywall.model";
type UnauthenticatedTrialFocusPaywallExperimentProps = {
  readonly model: PaywallModel;
  readonly shopLinks: ShopLinks;
};
export function UnauthenticatedTrialFocusPaywallExperiment({
  model,
  shopLinks
}: UnauthenticatedTrialFocusPaywallExperimentProps) {
  const PaywallSplit = useExperiment(<UnauthenticatedTrialFocusPaywallSplitA model={model} shopLinks={shopLinks} position="pw-adtr-btn-a" />, <UnauthenticatedTrialFocusPaywallSplitB model={model} position="pw-adtr-btn-b" shopLinks={shopLinks} />, <UnauthenticatedTrialFocusPaywallSplitA model={model} position="pw-adtr-btn" shopLinks={shopLinks} />);
  return <>{PaywallSplit}</>;
}