import { clsx } from "clsx";
import type { ReactNode } from "react";
type GridProps = {
  readonly className?: string;
  readonly children: ReactNode;
  readonly gap?: number;
  readonly columns?: number;
};
const gapByNumber: Readonly<Record<number, string>> = {
  0: "gap-0",
  3: "gap-3",
  4: "gap-4"
};
const columnsByNumber: Readonly<Record<number, string>> = {
  1: "lg:grid-cols-1",
  7: "lg:grid-cols-7",
  12: "lg:grid-cols-12"
};
export function Grid({
  children,
  className,
  columns = 12,
  gap = 4
}: GridProps) {
  return <div className={clsx("grid grid-cols-1", gapByNumber[gap], columnsByNumber[columns], className)} data-sentry-component="Grid" data-sentry-source-file="Grid.component.tsx">
      {children}
    </div>;
}