import { RegularLinkButton } from "@/components/Button/RegularLinkButton/RegularLinkButton.component";
import { Card } from "@/components/Card/Card.component";
import { CardBody } from "@/components/Card/_components/CardBody/CardBody.component";
import { CardHeading } from "@/components/Card/_components/CardHeading/CardHeading.component";
import { Translation } from "@/components/Translation/Translation.component";
import type { Fetchable } from "@/services/utilities/fetchable";
import { initial, isPending } from "@/services/utilities/fetchable";
type ShopCardProps = {
  readonly url: string;
  readonly variant?: "outline" | "primary";
  readonly state?: Fetchable;
  readonly siteName: string;
  readonly position?: string;
};
export function ShopCard({
  state = initial(),
  url,
  variant = "primary",
  siteName,
  position
}: ShopCardProps) {
  return <Card data-sentry-element="Card" data-sentry-component="ShopCard" data-sentry-source-file="ShopCard.component.tsx">
      <CardHeading data-sentry-element="CardHeading" data-sentry-source-file="ShopCard.component.tsx">
        <Translation da="Interesseret i et abonnement?" de="Interessiert an einem Abonnement?" en="Interested in a subscription?" no="Interessert i et abonnement?" sv="Intresserad av en prenumeration?" data-sentry-element="Translation" data-sentry-source-file="ShopCard.component.tsx" />
      </CardHeading>

      <CardBody data-sentry-element="CardBody" data-sentry-source-file="ShopCard.component.tsx">
        <Translation da={`Du kan også købe et personligt abonnement. Så har du altid adgang til alt indhold på ${siteName}. Og med et virksomhedsabonnement vil både du og dine kolleger få fuld adgang med det samme.`} de={`Sie können auch ein persönliches Abonnement kaufen. So haben Sie immer Zugriff auf alle Inhalte auf ${siteName}. Und mit einem Unternehmensabonnement erhalten Sie und Ihre Kollegen sofort vollen
          Zugriff.`} en={`You can also buy a personal subscription. That way, you always have access to all content on ${siteName}. And with a company subscription, both you and your colleagues will get full access right away.`} no={`Du kan også kjøpe et personlig abonnement. Så har du alltid tilgang til alt innhold på ${siteName}. Og med et bedriftsabonnement vil både du og dine kolleger få full tilgang med en gang.`} sv={`Du kan också köpa en personlig prenumeration. På så sätt har du alltid tillgång till allt innehåll på ${siteName}. Och med en företagsprenumeration får både du och dina kollegor full tillgång direkt.`} data-sentry-element="Translation" data-sentry-source-file="ShopCard.component.tsx" />
      </CardBody>

      <RegularLinkButton href={url} position={position} variant={variant} isPending={isPending(state)} data-sentry-element="RegularLinkButton" data-sentry-source-file="ShopCard.component.tsx">
        <Translation da="Kom i gang" de="Loslegen" en="Get started" no="Kom i gang" sv="Komma igång" data-sentry-element="Translation" data-sentry-source-file="ShopCard.component.tsx" />
      </RegularLinkButton>
    </Card>;
}