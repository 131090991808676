"use client";
import type { ReactNode } from "react";
import { useCookiebotConsent } from "./cookiebot/use-cookiebot-consent.hook";
import { useJpmuid } from "./tracking/jpmuid/use-jpmuid.hook";

const moduloDivisor = 2;

function variantFromJpmuid(jpmuid: string) {
  // calculate the total sum of code points of the jpmuid string and return the modulo, which is 0 or 1
  return (
    [...jpmuid].reduce((sum, char) => sum + (char.codePointAt(0) ?? 0), 0) %
    moduloDivisor
  );
}

export function useExperiment(
  VariantA: ReactNode,
  VariantB: ReactNode,
  DefaultVariant: ReactNode,
): ReactNode {
  const { jpmuid } = useJpmuid();
  const { submitted, statistics } = useCookiebotConsent();

  if (!(submitted && statistics)) {
    return DefaultVariant;
  }

  return variantFromJpmuid(jpmuid) ? VariantA : VariantB;
}
