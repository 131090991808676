import { HtmlContent } from "@/components/HtmlContent/HtmlContent.component";
import { Link } from "@/components/Link/Link.component";
import { RequiresSubscriptionLabel } from "@/components/RequiresSubscriptionLabel/RequiresSubscriptionLabel.component";
import { ArticleTeaserImage } from "@/components/images/ArticleTeaserImage/ArticleTeaserImage.component.tsx";
import type { ImageRatio } from "@/components/images/image.types.ts";
import type { ArticleTeaserModel } from "@/services/api/content/models/article-teaser-model.types";
import { tailwindScreenSize } from "@/utilities/get-tailwind-config.ts";
import clsx from "clsx";
type ArticleTeaserListItemProps = {
  readonly model: ArticleTeaserModel;
  readonly position?: string;
  readonly imageVisibility?: "lg:hidden";
  readonly ratio?: ImageRatio;
};
export function ArticleTeaserListItem({
  imageVisibility,
  model: {
    content,
    requiresSubscription
  },
  position,
  ratio = "4:3"
}: ArticleTeaserListItemProps) {
  const {
    html,
    kicker
  } = content.headline;
  const imageClassName: Record<ImageRatio, string> = {
    "3:2": "aspect-3/2",
    "4:3": "aspect-4/3",
    "16:9": "aspect-16/9"
  };
  const imageMapping: Record<ImageRatio, string> = {
    "3:2": content.supportingImage?.aspectRatio3By2 || "",
    "4:3": content.supportingImage?.aspectRatio4By3 || "",
    "16:9": content.supportingImage?.aspectRatio16By9 || ""
  };
  return <article className="grid auto-cols-fr grid-flow-col gap-x-2" data-k5a-pos={position} data-sentry-component="ArticleTeaserListItem" data-sentry-source-file="ArticleTeaserListItem.component.tsx">
      <div className={clsx("col-span-2 lg:col-span-3", imageVisibility)}>
        {content.supportingImage !== null ? <ArticleTeaserImage targetUrl={content.targetUrl} src={imageMapping[ratio]} alternativeText={content.supportingImage.alternativeText} className={imageClassName[ratio]} sourceSetSize={`(max-width: ${tailwindScreenSize.md}) 40vw, 230px`} /> : null}
      </div>

      <div className="col-span-3 lg:col-span-9">
        <Link className="mb-2 block hover:underline" href={content.targetUrl} position="head" data-sentry-element="Link" data-sentry-source-file="ArticleTeaserListItem.component.tsx">
          <h1 className="hyphens-manual font-heading text-xl font-theme-heading-weight lg:text-2xl">
            {kicker !== null ? <span className="text-primary">{kicker} </span> : null}
            <span dangerouslySetInnerHTML={{
            // biome-ignore lint/style/useNamingConvention: Biome does not support
            __html: html
          }} />
          </h1>
        </Link>

        {content.lead !== null ? <Link className="hidden text-base lg:block" href={content.targetUrl} position="lead">
            <HtmlContent className="leading-snug text-dim transition-colors duration-150 hover:text-black" html={content.lead.html} />
          </Link> : null}

        {requiresSubscription ? <RequiresSubscriptionLabel className="text-primary lg:mt-3" /> : null}
      </div>
    </article>;
}