import { RegularButton } from "@/components/Button/RegularButton/RegularButton.component";
import { Card } from "@/components/Card/Card.component";
import { CardHeading } from "@/components/Card/_components/CardHeading/CardHeading.component";
import { ErrorMessage } from "@/components/ErrorMessage/ErrorMessage.component";
import { ConsumerPerks } from "@/components/PerksList/_components/ConsumerPerks.component";
import { Translation } from "@/components/Translation/Translation.component";
import { hasFailed, initial, isPending } from "@/services/utilities/fetchable";
import type { ErrorMessageStatus, Fetchable } from "@/services/utilities/fetchable";
type TrialCardProps = {
  readonly model: {
    readonly trialDays: number;
    readonly variant?: "primary" | "secondary";
  };
  readonly state?: Fetchable;
  readonly onClick: VoidFunction;
};
export const trialCardConfirmationTranslations = {
  da: "Prøv nu",
  de: "Jetzt testen",
  en: "Try now",
  no: "Prøv nå",
  sv: "Prova nu"
};
export function TrialCard({
  model: {
    trialDays,
    variant = "primary"
  },
  onClick,
  state = initial()
}: TrialCardProps) {
  return <Card elevated={true} data-sentry-element="Card" data-sentry-component="TrialCard" data-sentry-source-file="TrialCard.component.tsx">
      <CardHeading data-sentry-element="CardHeading" data-sentry-source-file="TrialCard.component.tsx">
        <Translation da="Gratis prøveabonnement" de="Kostenlose Testversion" en="Free trial subscription" no="Gratis prøveabonnement" sv="Gratis testabonnemang" data-sentry-element="Translation" data-sentry-source-file="TrialCard.component.tsx" />
      </CardHeading>

      <p className="text-left text-sm md:text-base">
        <Translation da={`Med ${trialDays} dages gratis prøve får du:`} de={`Mit ${trialDays} Tagen kostenlosem Test erhalten Sie:`} en={`With ${trialDays} days free trial you get:`} no={`Med ${trialDays} dagers gratis prøve får du:`} sv={`Med ${trialDays} dagars gratis prov får du:`} data-sentry-element="Translation" data-sentry-source-file="TrialCard.component.tsx" />
      </p>

      <ConsumerPerks data-sentry-element="ConsumerPerks" data-sentry-source-file="TrialCard.component.tsx" />

      <RegularButton isPending={isPending(state)} onClick={onClick} testId="add-trial-button" variant={variant} data-sentry-element="RegularButton" data-sentry-source-file="TrialCard.component.tsx">
        <Translation model={trialCardConfirmationTranslations} data-sentry-element="Translation" data-sentry-source-file="TrialCard.component.tsx" />
      </RegularButton>

      {hasFailed(state) ? <ErrorMessage status={state.errorMessage as ErrorMessageStatus} /> : null}
    </Card>;
}