"use client";

import type { ShopLinks } from "@/app/_api/shop/_models/shop-links.model";
import { useSite } from "@/contexts/site/site.context";
import { usePathname, useSearchParams } from "next/navigation";
import { AddTrialLink } from "../AddTrialLink/AddTrialLink.component";
import { Link } from "../Link/Link.component";
import { Translation } from "../Translation/Translation.component";
import { ConsumerCard } from "./_components/ConsumerCard/ConsumerCard.components";
import { CorporateCard } from "./_components/CorporateCard/CorporateCard.components";
import { PaywallLayout } from "./_components/PaywallLayout/PaywallLayout.component";
import { PaywallLoginNotification } from "./_components/PaywallLoginNotification/PaywallLoginNotification.component";
import { PaywallTitle } from "./_components/PaywallTitle/PaywallTitle.component";
import type { PaywallModel } from "./models/paywall.model";
type PaywallProps = {
  readonly className?: string;
  readonly model: PaywallModel;
  readonly shopLinks: ShopLinks;
};
const trialLinkTranslations = {
  da: "Få et uforpligtende prøveabonnement her.",
  de: "Erhalten Sie hier ein unverbindliches Probeabonnement.",
  en: "Get a non-binding trial subscription here.",
  no: "Få et uforpliktende prøveabonnement her.",
  sv: "Få ett icke-bindande provabonnemang här."
};
export function UnauthenticatedPaywall({
  className,
  model,
  shopLinks: {
    corporateUrl,
    consumerUrl
  }
}: PaywallProps) {
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const {
    name: siteName
  } = useSite();
  const {
    apps,
    articleId,
    trial
  } = model;
  const searchParamsString = searchParams.toString();
  const addTrialUrl = `/profile/create/trial?redirectUrl=${encodeURIComponent(`${pathname}${searchParamsString ? `?${searchParamsString}` : ""}`)}`;
  return <PaywallLayout className={className} data-sentry-element="PaywallLayout" data-sentry-component="UnauthenticatedPaywall" data-sentry-source-file="UnauthenticatedPaywall.component.tsx">
      <PaywallLoginNotification data-sentry-element="PaywallLoginNotification" data-sentry-source-file="UnauthenticatedPaywall.component.tsx" />

      <PaywallTitle model={{
      da: `Køb abonnement og få adgang til hele ${siteName}`,
      de: `Abonnement kaufen und Zugang zu ${siteName} erhalten`,
      en: `Buy a subscription and get access to all of ${siteName}`,
      no: `Kjøp abonnement og få tilgang til hele ${siteName}`,
      sv: `Köp en prenumeration och få tillgång till hela ${siteName}`
    }} data-sentry-element="PaywallTitle" data-sentry-source-file="UnauthenticatedPaywall.component.tsx" />

      <ConsumerCard url={consumerUrl} variant="primary" position="pw-bbtn" data-sentry-element="ConsumerCard" data-sentry-source-file="UnauthenticatedPaywall.component.tsx" />

      <CorporateCard apps={apps} url={corporateUrl} variant="primary" position="pw-co-bbtn" data-sentry-element="CorporateCard" data-sentry-source-file="UnauthenticatedPaywall.component.tsx" />

      {trial !== null ? <AddTrialLink addTrialComponent={<Link testId="unauthenticated-add-trial-button" className="text-primary hover:underline" href={addTrialUrl} position="pw-ct-tr">
              <Translation model={trialLinkTranslations} />
            </Link>} articleId={articleId} numberOfTrialDays={trial.numberOfFreeTrialDays} /> : null}
    </PaywallLayout>;
}