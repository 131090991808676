"use client";

import type { ShopLinks } from "@/app/_api/shop/_models/shop-links.model";
import { useExperiment } from "@/hooks/use-experiment.hook";
import { AuthenticatedTrialFocusPaywallSplitA } from "./AuthenticatedTrialFocusPaywallSplitA.component";
import { AuthenticatedTrialFocusPaywallSplitB } from "./AuthenticatedTrialFocusPaywallSplitB.component";
import type { PaywallModel } from "./models/paywall.model";
type AuthenticatedTrialFocusPaywallExperimentProps = {
  readonly model: PaywallModel;
  readonly shopLinks: ShopLinks;
};
export function AuthenticatedTrialFocusPaywallExperiment({
  model,
  shopLinks
}: AuthenticatedTrialFocusPaywallExperimentProps) {
  const PaywallSplit = useExperiment(<AuthenticatedTrialFocusPaywallSplitA model={model} shopLinks={shopLinks} position="pw-adtr-btn-a" />, <AuthenticatedTrialFocusPaywallSplitB model={model} shopLinks={shopLinks} position="pw-adtr-btn-b" />, <AuthenticatedTrialFocusPaywallSplitA model={model} shopLinks={shopLinks} position="pw-adtr-btn" />);
  return <>{PaywallSplit}</>;
}