import clsx from "clsx";
import type { ReactNode } from "react";
type AsideProps = {
  readonly children?: ReactNode;
  readonly isFullWidth?: boolean;
  readonly className?: string;
};
export function Aside({
  children,
  className,
  isFullWidth = false
}: AsideProps) {
  return <aside className={clsx(className, "relative mr-2 w-full max-w-prose border-b border-silver pb-3 before:absolute before:right-0 before:top-0 before:block before:h-0 before:w-8 before:bg-silver", !isFullWidth && "md:float-left md:clear-both 2xl:-ml-10 md:w-36 md:border-r md:py-2 md:pr-2 md:before:h-px")} data-sentry-component="Aside" data-sentry-source-file="Aside.component.tsx">
      {children}
    </aside>;
}