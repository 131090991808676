"use client";

import type { ShopLinks } from "@/app/_api/shop/_models/shop-links.model";
import { conversionEvents } from "@/components/Tracking/Kilkaya/kilkaya.types";
import { useSiteConfiguration } from "@/contexts/site-configuration/site-configuration.context";
import { useSite } from "@/contexts/site/site.context";
import { useKilkayaClickEvent } from "@/hooks/tracking/kilkaya/use-kilkaya-tracking.hook";
import { useTracking } from "@/hooks/tracking/use-tracking";
import { useTrackingVersion } from "@/hooks/tracking/use-tracking-version";
import type { Fetchable } from "@/services/utilities/fetchable";
import { initial, pending } from "@/services/utilities/fetchable";
import { usePathname } from "next/navigation";
import { useState } from "react";
import { SwitchFetchable } from "../SwitchFetchable/SwitchFetchable.component";
import { PaywallLayout } from "./_components/PaywallLayout/PaywallLayout.component";
import { PaywallTitle } from "./_components/PaywallTitle/PaywallTitle.component";
import { ShopCard } from "./_components/ShopCard/ShopCard.component";
import { SuccessBox } from "./_components/SuccessBox/SuccessBox.component";
import { TrialCard } from "./_components/TrialCard/TrialCard.component";
import { useAddTrial } from "./_hooks/use-add-trial.hook";
import type { PaywallModel } from "./models/paywall.model";
type AuthenticatedTrialFocusPaywallSplitAProps = {
  readonly className?: string;
  readonly model: PaywallModel;
  readonly initialSubmissionState?: Fetchable;
  readonly shopLinks: ShopLinks;
  readonly position: string;
};
export function AuthenticatedTrialFocusPaywallSplitA({
  className,
  initialSubmissionState = initial(),
  model,
  shopLinks: {
    consumerUrl
  },
  position
}: AuthenticatedTrialFocusPaywallSplitAProps) {
  const pathname = usePathname();
  const {
    features: {
      loginProvider
    }
  } = useSiteConfiguration();
  const {
    domain,
    name: siteName
  } = useSite();
  const [containerState, setContainerState] = useState(initialSubmissionState);
  const {
    addTrialToCurrentProfile
  } = useAddTrial();
  const {
    trackClick: trackClickV2
  } = useTracking();
  const {
    trackClick: trackClickV1
  } = useKilkayaClickEvent();
  const trackingVersion = useTrackingVersion();
  const {
    apps,
    articleId,
    trial
  } = model;
  const isTrialCardVisible = trial !== null && !trial.isUserTrialExpired && trial.numberOfFreeTrialDays > 0;
  async function trialClick() {
    setContainerState(pending());
    if (trackingVersion === "v2") {
      trackClickV2({
        position: position,
        toUrl: `https://${domain}${pathname}`,
        url: `https://${domain}${pathname}`
      });
    }
    if (trackingVersion === "v1") {
      trackClickV1({
        position: position,
        toUrl: `https://${domain}${pathname}`,
        url: `https://${domain}${pathname}`
      });
    }
    const result = await addTrialToCurrentProfile(articleId);
    setContainerState(result);
  }
  return <SwitchFetchable fetchable={containerState} renderDefault={() => <PaywallLayout className={className}>
          <PaywallTitle model={{
      da: `Du er logget ind, men du er ikke tilknyttet et aktivt abonnement på ${siteName}`,
      de: `Sie sind angemeldet, aber mit keinem aktiven Abonnement für ${siteName} verbunden`,
      en: `You are logged in, but you do not have an active subscription to ${siteName}`,
      no: `Du er logget inn, men du er ikke tilknyttet et aktivt abonnement på ${siteName}`,
      sv: `Du är inlogg, men har inte ett aktivt abonnemang på ${siteName}`
    }} />

          {isTrialCardVisible ? <TrialCard model={{
      trialDays: trial.numberOfFreeTrialDays
    }} onClick={trialClick} state={containerState} /> : null}

          <ShopCard siteName={siteName} url={consumerUrl} state={containerState} variant="outline" position="pw-all-btn" />
        </PaywallLayout>} renderSucceeded={() => <SuccessBox className={className} model={{
    appStore: apps?.appStoreUrl ?? null,
    conversionEventTags: conversionEvents.addTrialArticleEvent,
    googlePlayUrl: apps?.googlePlayUrl ?? null,
    loginProvider,
    state: containerState
  }} />} data-sentry-element="SwitchFetchable" data-sentry-component="AuthenticatedTrialFocusPaywallSplitA" data-sentry-source-file="AuthenticatedTrialFocusPaywallSplitA.component.tsx" />;
}