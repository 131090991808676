"use client";

import type { ShopLinks } from "@/app/_api/shop/_models/shop-links.model";
import { useSite } from "@/contexts/site/site.context";
import { useKilkayaClickEvent } from "@/hooks/tracking/kilkaya/use-kilkaya-tracking.hook";
import { useTracking } from "@/hooks/tracking/use-tracking";
import { useTrackingVersion } from "@/hooks/tracking/use-tracking-version";
import { usePathname, useRouter, useSearchParams } from "next/navigation";
import { ConsumerCard } from "./_components/ConsumerCard/ConsumerCard.components";
import { CorporateCard } from "./_components/CorporateCard/CorporateCard.components";
import { PaywallLayout } from "./_components/PaywallLayout/PaywallLayout.component";
import { PaywallLoginNotification } from "./_components/PaywallLoginNotification/PaywallLoginNotification.component";
import { PaywallTitle } from "./_components/PaywallTitle/PaywallTitle.component";
import { TrialCard } from "./_components/TrialCard/TrialCard.component";
import type { PaywallModel } from "./models/paywall.model";
type UnauthenticatedTrialFocusPaywallSplitBProps = {
  readonly className?: string;
  readonly model: PaywallModel;
  readonly shopLinks: ShopLinks;
  readonly position: string;
};
export default function UnauthenticatedTrialFocusPaywallSplitB({
  className,
  model,
  shopLinks: {
    corporateUrl,
    consumerUrl
  },
  position
}: UnauthenticatedTrialFocusPaywallSplitBProps) {
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const router = useRouter();
  const {
    domain,
    name: siteName,
    subscription: {
      trialDays
    }
  } = useSite();
  const {
    trackClick: trackClickV2
  } = useTracking();
  const {
    trackClick: trackClickV1
  } = useKilkayaClickEvent();
  const trackingVersion = useTrackingVersion();
  const {
    apps,
    trial
  } = model;
  const isTrialCardVisible = trial !== null && !trial.isUserTrialExpired && trial.numberOfFreeTrialDays > 0;
  const notLoggedInTitleTranslation = isTrialCardVisible ? {
    da: `Prøv ${siteName} gratis i ${trialDays} dage`,
    de: `Testen Sie ${siteName} ${trialDays} Tage lang kostenlos`,
    en: `Try ${siteName} for free for ${trialDays} days`,
    no: `Prøv ${siteName} gratis i ${trialDays} dager`,
    sv: `Prova ${siteName} gratis i ${trialDays} dagar`
  } : {
    da: `Køb abonnement og få adgang til hele ${siteName}`,
    de: `Abonnement kaufen und Zugang zu ${siteName} erhalten`,
    en: `Buy a subscription and get access to all of ${siteName}`,
    no: `Kjøp abonnement og få tilgang til hele ${siteName}`,
    sv: `Köp en prenumeration och få tillgång till hela ${siteName}`
  };
  const onAddTrialLinkClick = () => {
    const searchParamsString = searchParams.toString();
    const url = `/profile/create/trial?redirectUrl=${encodeURIComponent(`${pathname}${searchParamsString ? `?${searchParamsString}` : ""}`)}`;
    if (trackingVersion === "v2") {
      trackClickV2({
        position: position,
        toUrl: `https://${domain}${pathname}`,
        url: `https://${domain}${pathname}`
      });
    }
    if (trackingVersion === "v1") {
      trackClickV1({
        position: position,
        toUrl: `https://${domain}${pathname}`,
        url: `https://${domain}${pathname}`
      });
    }
    router.push(url);
  };
  return <PaywallLayout className={className} data-sentry-element="PaywallLayout" data-sentry-component="UnauthenticatedTrialFocusPaywallSplitB" data-sentry-source-file="UnauthenticatedTrialFocusPaywallSplitB.component.tsx">
      <PaywallLoginNotification data-sentry-element="PaywallLoginNotification" data-sentry-source-file="UnauthenticatedTrialFocusPaywallSplitB.component.tsx" />
      <PaywallTitle model={notLoggedInTitleTranslation} data-sentry-element="PaywallTitle" data-sentry-source-file="UnauthenticatedTrialFocusPaywallSplitB.component.tsx" />

      {isTrialCardVisible ? <TrialCard model={{
      trialDays: trial.numberOfFreeTrialDays
    }} onClick={onAddTrialLinkClick} /> : null}

      <ConsumerCard url={consumerUrl} variant="outline" data-sentry-element="ConsumerCard" data-sentry-source-file="UnauthenticatedTrialFocusPaywallSplitB.component.tsx" />

      <CorporateCard apps={apps} url={corporateUrl} variant="outline" data-sentry-element="CorporateCard" data-sentry-source-file="UnauthenticatedTrialFocusPaywallSplitB.component.tsx" />
    </PaywallLayout>;
}