import type { ArticleTeaserModel } from "@/services/api/content/models/article-teaser-model.types";
import { clsx } from "clsx";
type ArticleTeaserHeadingProps = {
  readonly className?: string;
  readonly title: ArticleTeaserModel.HtmlAndText;
  readonly kickerColor?: "text-primary" | "text-secondary";
};
export function ArticleTeaserHeading({
  className,
  kickerColor = "text-primary",
  title: {
    html,
    kicker
  }
}: ArticleTeaserHeadingProps) {
  return <h1 className={clsx("hyphens-manual break-words font-heading", className)} data-k5a-pos="head" data-sentry-component="ArticleTeaserHeading" data-sentry-source-file="ArticleTeaserHeading.component.tsx">
      {kicker !== null ? <span className={kickerColor}>{kicker} </span> : null}
      <span dangerouslySetInnerHTML={{
      // biome-ignore lint/style/useNamingConvention: Biome does not support
      __html: html
    }} />
    </h1>;
}